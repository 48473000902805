import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import VueResource from 'vue-resource';
import './assets/fonts/font.scss';
import axios from 'axios'
import plugin from './plugin'

import visibility from 'vue-visibility-change';
Vue.use(visibility);

//引入生成pdf插件
import htmlToPdf from './utils/htmlToPdf';
Vue.use(htmlToPdf)

Vue.use(plugin)
//@ts-ignore
// import getLoginState from "../api";
// 全局变量
window.showLoginOuttimeWarningTip = true // 是否显示登陆超时提示
import './utils/flexible' // 引入rem自适应
// import "lib-flexible-computer";
// Vue.use(VueRouter)
Vue.config.productionTip = false

// const routes = new VueRouter({
//   scrollBehavior:(to,from, savePosition)=>{
//       if(to.hash) return {selector:to.hash};//跳转到锚点
//       return savePosition || {x:0,y:0};//回归历史滚动位置
//   },
//   router
// });
// routes.beforeEach((to,from,next)=>{
//   document.title = to.meta.title || '';//路由发生变化修改页面title
//   next()
// });


//引入第三方插件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { JSEncrypt } from 'jsencrypt'
Vue.prototype.$JSEncrypt = JSEncrypt
import baseURL from './config/baseUrl.js'

const Axios = axios.create({
  baseURL: baseURL, 
  timeout: 300 * 1000, // 超时时间单位是ms
})

router.beforeEach((to, from, next) => {
  if (to.path !== from.path || to.path === "/") {
    if (to.path === '/' || to.path === '/login'
      || to.path === '/forgetPwd' || to.path === "/enroll"
    ) {
      next();
    } else {
      if (from.path !== '/login') {
        Axios.get("/exam/api/v1/check/status", {
          headers: {
            "Authorization": localStorage.token || ""
          },
        }).then(res => {
          if (from.path == "/configPaper") {
            // ElementUI.Message({
            //   showClose: true,
            //   message: "试卷信息未保存",
            //   type: "warning",
            // });
            // window.alert(
            //   "试卷信息未保存");
          }
          if (res.data.code === 401) {
            ElementUI.Message({
              showClose: true,
              message: "登录已超时,请重新登录",
              type: "warning",
            });
            localStorage.removeItem("token")
            localStorage.removeItem("userData")
            router.push({ path: "/login" })

          } else if (res.data.code === 409) {
            ElementUI.Message({
              showClose: true,
              message: "账号已在其他地方登录,请重新登录",
              type: "warning",
            });
            localStorage.removeItem("token")
            localStorage.removeItem("userData")
            router.push({ path: "/login" })
          } else {
            next();
          }
        });
      } else {
        next();
      }
    }
  }
});
Vue.use(ElementUI);
Vue.use(VueResource);
new Vue({
  router,
  // @ts-ignore
  store,

  render: h => h(App)
}).$mount('#app')