import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  install(Vue, options) {
    Vue.prototype.getPdf = async function (title, dom) {
      //打印的内容
      var title = this.htmlTitle;
      var ele = document.querySelector("#printId");
      //根据特定的class 筛选出来的List (防止文字被切断)
      let item = ele.querySelectorAll(".item");

      let order = JSON.parse(ele.getAttribute('data-order')).value
      console.log(order,'order');

      let num = JSON.parse(ele.getAttribute('data-num'))
      let arr = []
      for(let i = 0; i < num; i++) {
        arr.push([])
      }
      item.forEach(val => {
        if(val.getAttribute('data-type') === 'single') {
          arr[order.single - 1].push(val)
        }
        if(val.getAttribute('data-type') === 'multiple') {
          arr[order.multiple - 1].push(val)
        }
        if(val.getAttribute('data-type') === 'analyse') {
          arr[order.analyse - 1].push(val)
        }
        if(val.getAttribute('data-type') === 'blank') {
          arr[order.blank - 1].push(val)
        }
        if(val.getAttribute('data-type') === 'inference') {
          arr[order.inference - 1].push(val)
        }
      })
      console.log(item, 'item');
      console.log(arr,'arr');

      let itemOrder = [item[0], ...arr.flat(2)]
      console.log(itemOrder,'itemOrder');

      let eleW = ele.offsetWidth; // 获得该容器的宽
      let eleH = ele.offsetHeight; // 获得该容器的高
      let eleOffsetTop = ele.offsetTop; // 获得该容器到文档顶部的距离
      let eleOffsetLeft = ele.offsetLeft; // 获得该容器到文档最左的距离

      var canvas = document.createElement("canvas");
      var abs = 0;

      let win_in =
        document.documentElement.clientWidth || document.body.clientWidth; // 获得当前可视窗口的宽度（不包含滚动条）
      let win_out = window.innerWidth; // 获得当前窗口的宽度（包含滚动条）

      if (win_out > win_in) {
        // abs = (win_o - win_i)/2;    // 获得滚动条长度的一半
        abs = (win_out - win_in) / 2; // 获得滚动条宽度的一半
        // console.log(a, '新abs');
      }
      canvas.width = eleW * 1; // 将画布宽&&高放大两倍
      canvas.height = eleH * 1;

      var context = canvas.getContext("2d");
      context.scale(2, 2);
      context.translate(-eleOffsetLeft - abs, -eleOffsetTop);
      // 这里默认横向没有滚动条的情况，因为offset.left(),有无滚动条的时候存在差值，因此
      // translate的时候，要把这个差值去掉

      var pdf = new jsPDF("", "pt", "a4");

      let position = 0; //页面偏移
      let pageHeight = 801.89; //PDF页面高度

      for (let i = 0; i < itemOrder.length; i++) {
        canvas = await html2canvas(itemOrder[i], { dpi: 600, useCORS: true, scale: 2 }); // scale 调整清晰度

        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        // a4纸的尺寸[595.28,841.89]
        var imgWidth = 535.28; //图片宽度 原是595.38，因左右留白30，减去60
        var imgHeight = (595.28 / contentWidth) * contentHeight; //图片高度
        var pageData = canvas.toDataURL("image/jpeg", 1.0);

        if (imgHeight < pageHeight) {
          if (position === 0) {
            //页面偏移为0
            position = 30; //第一张图片，上边距留白30，页面偏移从30开始
          }
        } else {
          //页高剩余的高度不足放下当前图片高度，则另起一页

          pdf.addPage(); //新增一张空白的页面
          position = 0; //页面偏移初始化
          pageHeight = 801.89; //页面高度初始化
          if (position === 0) {
            position = 30; //第一张图片，上边距留白30，页面偏移从30开始
          }
          //pdf.addImage(pageData, 'JPEG', 30, position, imgWidth, imgHeight); //添加图片
          //position = position + imgHeight;
          //pageHeight = pageHeight - imgHeight;
        }
        pdf.addImage(pageData, "JPEG", 30, position, imgWidth, imgHeight); //添加图片，30：为向左偏移30
        position = position + imgHeight; //新偏移 = 旧偏移 + 图片高度
        pageHeight = pageHeight - imgHeight; // 新页面高度 = 剩余页高 - 图片高度
      }
      pdf.save(title + ".pdf");
    };
  },
};
